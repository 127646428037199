var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(!_vm.isLoading && _vm.userOnFreeTariff)?_c('v-overlay',[_c('v-alert',{staticClass:"mx-2",attrs:{"color":"info","border":"top"}},[_c('v-row',[_c('v-col',{staticClass:"text-right mt-0 mb-0 pb-0",attrs:{"cols":"12"}},[_c('v-icon',{on:{"click":_vm.goBack}},[_vm._v("mdi-close")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"size":"50"}},[_vm._v("mdi-information")])],1),_c('v-col',{staticClass:"text-center font-bold",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('custom.free_tariff_warning'))+" ")]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mx-1 my-1",attrs:{"color":"success","loading":_vm.isLoadingSubscribeToPaidTariff},on:{"click":function($event){return _vm.subscribeToPaidTariff()}}},[_vm._v(" "+_vm._s(_vm.$t('custom.to_paid_plan'))+" ")])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mx-1 my-1",attrs:{"small":"","text":""},on:{"click":function($event){return _vm.toTariffOnSite()}}},[_vm._v(" "+_vm._s(_vm.$t('custom.tariff_more_info'))+" ")])],1)],1)],1)],1):_vm._e(),_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true,"color":"#1976d2","background-color":_vm.$vuetify.theme.dark ? '#000' : ''},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('v-card',[_c('v-card-title',[_c('h4',[_vm._v(_vm._s(_vm.$t("home.events")))]),_c('v-spacer'),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.connected
            ? _vm.$t('custom.connected')
            : _vm.$t('custom.disconnected'),
          placement: 'bottom-center',
          classes: ['info'],
          targetClasses: ['it-has-a-tooltip'],
          delay: {
            show: 500,
            hide: 500,
          },
        }),expression:"{\n          content: connected\n            ? $t('custom.connected')\n            : $t('custom.disconnected'),\n          placement: 'bottom-center',\n          classes: ['info'],\n          targetClasses: ['it-has-a-tooltip'],\n          delay: {\n            show: 500,\n            hide: 500,\n          },\n        }"}],class:'pulse mt-3 mx-2 ' + (_vm.connected ? 'pulse_success' : 'pulse_error')})],1),_c('v-divider'),_c('v-card-text',{style:(_vm.isMobile ? 'padding: 5px !important;' : '')},[_c('v-flex',[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('custom.search'),"single-line":"","clearable":"","hide-details":"","hide-no-data":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{class:!_vm.isMobile ? '' : 'is-mobile',attrs:{"items":_vm.operations,"headers":_vm.headers,"loading":_vm.isLoadingSearch,"items-per-page":20,"loading-text":_vm.$t('custom.loading_table'),"search":_vm.search,"server-items-length":_vm.total,"options":_vm.tableOptions,"page":_vm.page,"item-key":"id","sort-desc":[true],"mobile-breakpoint":"100","footer-props":{
          pageText: `{0} ${_vm.$t('custom.of')} {1}`,
          itemsPerPageText: _vm.$t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [20, 50, 100],
        }},on:{"update:serverItemsLength":function($event){_vm.total=$event},"update:server-items-length":function($event){_vm.total=$event},"update:options":function($event){_vm.tableOptions=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.formatCreatedAt(props.item.created_at))+" ")]),_c('td',[_vm._v(_vm._s(props.item.description))])])]}}])},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t("custom.no_data_in_table"))+" ")]),_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t("tables.no_search_result", { msg: _vm.search }))+" ")])],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }