<template>
  <span v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <!-- <v-flex md12>
          <h2>{{ $t('custom.analitics') }}</h2>
        </v-flex> -->
    <v-container fluid fill-height>
      <!-- <v-layout> -->

      <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'" class="align-center" :height="isMobile ? 200 : ''">
        <v-flex :class="isMobile ? '' : 'd-flex align-center justify-space-between'">
          <v-toolbar-title>{{ $t("analitics.common_cars") }} {{ $t("custom.number")
            }}{{ report.id }} {{ start_date_formatted }} -
            {{ end_date_formatted }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-checkbox v-model="report.with_property" :label="$t('analitics.with_property_short')"
            @click="with_property_warn()" readonly class="mt-5"></v-checkbox>
          <v-spacer></v-spacer>
          <v-text-field v-model="effective" type="number" :label="$t('analitics.investment_on')"
            hide-details></v-text-field>
          <v-btn icon :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
            }/${$root.$i18n.locale}/categories/8/guides/25`" target="_blank">
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </v-flex>
      </v-toolbar>
      <!-- По машинам -->
      <v-flex v-if="report.state == 'created'" md12 class="d-inline-flex">
        <v-flex md12 class="mt-2">
          <v-card-text>
            <h2>{{ $t("analitics.average_indicators") }}</h2>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">
                      {{ $t("analitics.incomes") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.expences_on_car") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.profit") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.average_profit") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.average_month_profit") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.investment_100k") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.work_days") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.days_in_rent") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.percent_loading") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.bookings_mileage") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.average_month_mileage") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.km_cost") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.km_income") }}
                    </th>
                    <th class="text-center">
                      {{ $t("analitics.average_count") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">{{ allIncome() }}{{ findCurrencySymbol(report.currency_id) }}</td>
                    <td class="text-center">
                      {{ allExpences() }}{{ findCurrencySymbol(report.currency_id) }}
                    </td>
                    <td class="text-center">{{ allProfit() }}{{ findCurrencySymbol(report.currency_id) }}</td>
                    <td class="text-center">
                      {{ averageProfit() }}{{ findCurrencySymbol(report.currency_id) }}
                    </td>
                    <td class="text-center">
                      {{ averageMonthProfit() }}{{ findCurrencySymbol(report.currency_id) }}
                    </td>
                    <td class="text-center">
                      {{ averageEffectiveProfit() }}{{ findCurrencySymbol(report.currency_id) }}
                    </td>
                    <td class="text-center">
                      {{ allWorkDays() }}
                    </td>
                    <td class="text-center">
                      {{ allBookingDays() }}
                    </td>
                    <td class="text-center">{{ averageLoading() }}%</td>
                    <td class="text-center">
                      {{ allBookingMileage() }}
                    </td>
                    <td class="text-center">
                      {{ averageBookingMileage() }}
                    </td>
                    <td class="text-center">
                      {{ averageCostKm() }}{{ findCurrencySymbol(report.currency_id) }}
                    </td>
                    <td class="text-center">
                      {{ averageIncomeKm() }}{{ findCurrencySymbol(report.currency_id) }}
                    </td>
                    <td class="text-center">
                      {{ averageCost() }}{{ findCurrencySymbol(report.currency_id) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-text>
            <h2 class="mb-2">{{ $t("analitics.for_cars") }}</h2>
            <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
              <v-select v-model="selected_cars" class="mt-5 mr-5" clearable :items="cars_codes" multiple
                :label="$t('custom.car')"></v-select>
              <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('custom.search')" single-line
                clearable hide-details></v-text-field>
              <v-spacer></v-spacer>
              <v-checkbox v-model="with_sold" :label="$t('analitics.show_unactive')" class="mt-5"></v-checkbox>
            </v-toolbar>
            <v-data-table :class="!isMobile ? 'mt-5' : 'mt-5 is-mobile'" dense :headers="headers"
              :items="filtered_analitics_data" :loading="isLoading" :loading-text="$t('custom.loading_table')"
              :search="search" :items-per-page="50" :sort-by="['created_at']" mobile-breakpoint="100"
              :sort-desc="[true]" :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [20, 50, 100, -1],
              }">
              <template slot="item" slot-scope="props">
                <tr @dblclick="replaceToCar(props.item.car_id)"
                  :class="props.item.active ? '' : 'grey--text text--lighten-1'">
                  <td nowrap class="text-center">
                    <router-link :to="{ path: `/cars/${props.item.car_id}` }">{{
                      props.item.car_code
                      }}</router-link>
                  </td>
                  <td nowrap class="text-center">
                    {{ props.item.income_rub }}{{ findCurrencySymbol(report.currency_id) }}
                  </td>
                  <td nowrap class="text-center">
                    {{ props.item.expences_rub }}{{ findCurrencySymbol(report.currency_id) }}
                  </td>
                  <td nowrap class="text-center">
                    {{ props.item.profit }}{{ findCurrencySymbol(report.currency_id) }}
                  </td>
                  <td nowrap class="text-center">
                    {{
                      parseFloat(
                        props.item.other_expences_counts /
                        (filtered_analitics_data.length > 0
                          ? filtered_analitics_data.length
                          : 1)
                      ).toFixed(2)
                    }}{{ findCurrencySymbol(report.currency_id) }}
                  </td>
                  <td nowrap class="text-center">
                    {{ props.item.average_profit }}{{ findCurrencySymbol(report.currency_id) }}
                  </td>
                  <td nowrap class="text-center" v-if="props.item.work_days > 30">
                    {{
                      parseInt(
                        props.item.average_profit / (props.item.work_days / 30)
                      )
                    }}{{ findCurrencySymbol(report.currency_id) }}
                  </td>
                  <td nowrap class="text-center" v-else>-</td>
                  <td nowrap class="text-center" v-if="
                    props.item.work_days > 0 && props.item.purchase_price > 0
                  ">
                    {{
                      parseInt(
                        (props.item.average_profit /
                          (props.item.work_days / 30) /
                          props.item.purchase_price) *
                        effective
                      )
                    }}{{ findCurrencySymbol(report.currency_id) }}
                  </td>
                  <td nowrap class="text-center" v-else>-</td>
                  <td nowrap class="text-center">{{ props.item.work_days }}</td>
                  <td nowrap class="text-center">
                    {{ props.item.bookings_days }}
                  </td>
                  <td nowrap class="text-center">
                    {{ props.item.loading_percentage }}%
                  </td>
                  <td nowrap class="text-center">
                    {{ props.item.bookings_mileage }} км
                  </td>
                  <td nowrap class="text-center" v-if="props.item.work_days > 30">
                    {{
                      parseFloat(
                        props.item.bookings_mileage /
                        (props.item.work_days / 30)
                      ).toFixed(2)
                    }}
                    {{ $t("custom.km") }}
                  </td>
                  <td nowrap class="text-center" v-else>-</td>
                  <td nowrap class="text-center" v-if="
                    props.item.work_days > 0 &&
                    props.item.bookings_mileage > 0
                  ">
                    {{
                      parseFloat(
                        props.item.expences_rub / props.item.bookings_mileage
                      ).toFixed(2)
                    }}{{ findCurrencySymbol(report.currency_id) }}
                  </td>
                  <td nowrap class="text-center" v-else>-</td>
                  <td nowrap class="text-center" v-if="props.item.bookings_mileage > 0">
                    {{
                      parseFloat(
                        props.item.average_profit / props.item.bookings_mileage
                      ).toFixed(2)
                    }}{{ findCurrencySymbol(report.currency_id) }}
                  </td>
                  <td nowrap class="text-center" v-else>-</td>
                  <td nowrap class="text-center">
                    {{ Math.round(props.item.bookings_average_cost)
                    }}{{ findCurrencySymbol(report.currency_id) }}
                  </td>
                  <td nowrap class="text-center" v-if="
                    props.item.purchase_price > 0 && props.item.sale_price > 0
                  ">
                    {{
                      props.item.profit -
                      props.item.purchase_price +
                      props.item.sale_price
                    }}{{ findCurrencySymbol(report.currency_id) }}
                  </td>
                  <td nowrap class="text-center" v-else>-</td>
                  <td nowrap class="text-center" v-if="
                    props.item.purchase_price > 0 &&
                    props.item.sale_price > 0 &&
                    props.item.bookings_mileage > 0
                  ">
                    {{
                      parseFloat(
                        (props.item.profit -
                          props.item.purchase_price +
                          props.item.sale_price) /
                        props.item.bookings_mileage
                      ).toFixed(2)
                    }}{{ findCurrencySymbol(report.currency_id) }}
                  </td>
                  <td nowrap class="text-center" v-else>-</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-flex>
      </v-flex>
      <v-flex v-else md12 class="text-center mt-10">
        {{ $t("analitics.report_in_progress") }}
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <br />
        <v-btn @click="$router.go()">{{ $t("custom.update") }}</v-btn>
      </v-flex>
    </v-container>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { GChart } from "vue-google-charts";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    let self = this;
    return {
      report: {},
      analitics_data: [],
      filtered_analitics_data: [],
      selected_cars: [],
      with_sold: false,
      property: true,
      cars_codes: [],
      effective: 100000,
      headers: [
        { text: this.$t("custom.car"), value: "car_code", align: "center" },
        {
          text: this.$t("analitics.incomes"),
          value: "income_rub",
          align: "center",
        },
        {
          text: this.$t("analitics.expences"),
          value: "expences_rub",
          align: "center",
        },
        { text: this.$t("analitics.profit"), value: "profit", align: "center" },
        {
          text: this.$t("analitics.expences_for_company"),
          value: "other_expences_counts",
          align: "center",
        },
        {
          text: this.$t("analitics.average_profit"),
          value: "average_profit",
          align: "center",
        },
        {
          text: this.$t("analitics.average_month_profit"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.investment_100k"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.work_days"),
          value: "work_days",
          align: "center",
        },
        {
          text: this.$t("analitics.days_in_rent"),
          value: "bookings_days",
          align: "center",
        },
        {
          text: this.$t("analitics.percent_loading"),
          value: "loading_percentage",
          align: "center",
        },
        {
          text: this.$t("analitics.bookings_mileage"),
          value: "bookings_mileage",
          align: "center",
        },
        {
          text: this.$t("analitics.average_month_mileage"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.km_cost"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.km_income"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.average_count"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.with_car"),
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("analitics.with_car_km"),
          sortable: false,
          align: "center",
        },
      ],
      search: "",
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (
      this.$store.getters.isLoggedIn &&
      (this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isGuest)
    ) {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/cars_reports/${this.$route.params.query}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.report = response.data;
          this.cars_codes = [];
          if (response.data.state == "created") {
            response.data.cars_data.forEach((car) =>
              self.cars_codes.push(car.car_code)
            );
            this.analitics_data = response.data.cars_data;
            this.filtered_analitics_data = this.analitics_data.filter(
              (item) => {
                return item.active == true;
              }
            );
          }
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.cars_report_load"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.$router.push({ path: `/dashboard` }).catch((err) => { });
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.only_admins"),
        text: this.error,
      });
    }
  },
  watch: {
    with_sold() {
      if (this.with_sold) {
        this.filtered_analitics_data = this.analitics_data;
      } else {
        this.filtered_analitics_data = this.analitics_data.filter((item) => {
          return item.active == true;
        });
      }
    },
    selected_cars() {
      let self = this;
      if (this.selected_cars.length > 0) {
        this.filtered_analitics_data = this.analitics_data.filter((car) =>
          self.selected_cars.includes(car.car_code)
        );
      } else {
        this.filtered_analitics_data = [];
        this.analitics_data.forEach((car) =>
          self.filtered_analitics_data.push(car)
        );
      }
    },
  },
  computed: {
    start_date_formatted() {
      return moment(this.report.start_date).format("DD.MM.YYYY");
    },
    end_date_formatted() {
      return moment(this.report.end_date).format("DD.MM.YYYY");
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    currencies() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currencies
      ) {
        return this.$store.getters.getCompany.company.currencies;
      } else {
        return [];
      }
    },
  },
  methods: {
    allIncome() {
      let income_rub = 0.0;
      this.analitics_data.forEach((item) =>
        item.income_counts.forEach((count) => (income_rub += count.sum))
      );
      return parseFloat(
        income_rub /
        (this.analitics_data[0].cars_numbers > 0
          ? this.analitics_data[0].cars_numbers
          : 1.0)
      ).toFixed(2);
    },
    allProfit() {
      let profit = 0.0;
      this.analitics_data.forEach((item) => (profit += item.profit));
      return parseFloat(
        profit /
        (this.analitics_data[0].cars_numbers > 0
          ? this.analitics_data[0].cars_numbers
          : 1.0)
      ).toFixed(2);
    },
    allExpences() {
      let expences = 0.0;
      this.analitics_data.forEach((item) =>
        item.expences_counts.forEach((count) => (expences += count.sum))
      );
      return parseFloat(
        expences /
        (this.analitics_data[0].cars_numbers > 0
          ? this.analitics_data[0].cars_numbers
          : 1.0)
      ).toFixed(2);
    },
    averageProfit() {
      let profit =
        (this.allIncome() - this.allExpences()) /
        (this.analitics_data[0].cars_numbers > 0
          ? this.analitics_data[0].cars_numbers
          : 1.0);
      return parseFloat(profit).toFixed(2);
    },
    averageMonthProfit() {
      let profit = 0.0;
      this.analitics_data.forEach((item) => {
        if (item.work_days > 30.0) {
          profit += Math.round(item.average_profit / (item.work_days / 30));
        }
      });
      if (
        profit &&
        (this.analitics_data[0].cars_numbers > 0
          ? this.analitics_data[0].cars_numbers
          : 1.0) > 0
      ) {
        profit =
          profit /
          (this.analitics_data[0].cars_numbers > 0
            ? this.analitics_data[0].cars_numbers
            : 1.0);
      }
      return Math.round(profit);
    },
    averageEffectiveProfit() {
      let self = this;
      let profit = 0.0;
      this.analitics_data.forEach((item) => {
        if (item.work_days > 0 && item.purchase_price > 0) {
          profit += Math.round(
            (item.average_profit /
              (item.work_days / 30) /
              item.purchase_price) *
            self.effective
          );
        }
      });
      if (
        profit &&
        (this.analitics_data[0].cars_numbers > 0
          ? this.analitics_data[0].cars_numbers
          : 1.0) > 0
      ) {
        profit =
          profit /
          (this.analitics_data[0].cars_numbers > 0
            ? this.analitics_data[0].cars_numbers
            : 1.0);
      }
      return Math.round(profit);
    },
    allWorkDays() {
      let days = 0;
      this.analitics_data.forEach((item) => (days += item.work_days));
      return days;
    },
    allBookingDays() {
      let days = 0;
      this.analitics_data.forEach((item) => (days += item.bookings_days));
      return days;
    },
    averageLoading() {
      let loading = 0.0;
      this.analitics_data.forEach(
        (item) => (loading += item.loading_percentage)
      );
      loading =
        loading /
        (this.analitics_data[0].cars_numbers > 0
          ? this.analitics_data[0].cars_numbers
          : 1.0);
      return parseFloat(loading).toFixed(2);
    },
    allBookingMileage() {
      let bookings_mileage = 0;
      this.analitics_data.forEach(
        (item) => (bookings_mileage += item.bookings_mileage)
      );
      return bookings_mileage;
    },
    averageBookingMileage() {
      let loading = 0.0;

      this.analitics_data.forEach((item) => {
        if (item.work_days > 0 && item.bookings_mileage > 0) {
          loading += item.bookings_mileage / (item.work_days / 30.0);
        }
      });
      return parseFloat(
        loading /
        (this.analitics_data[0].cars_numbers > 0
          ? this.analitics_data[0].cars_numbers
          : 1.0)
      ).toFixed(2);
    },
    averageCostKm() {
      let loading = 0.0;

      this.analitics_data.forEach((item) => {
        if (item.work_days > 0 && item.bookings_mileage > 0) {
          loading += item.expences_rub / item.bookings_mileage;
        }
      });
      loading =
        loading /
        (this.analitics_data[0].cars_numbers > 0
          ? this.analitics_data[0].cars_numbers
          : 1.0);
      return parseFloat(loading).toFixed(2);
    },
    averageIncomeKm() {
      let loading = 0.0;

      this.analitics_data.forEach((item) => {
        if (item.work_days > 0 && item.bookings_mileage > 0) {
          loading += item.average_profit / item.bookings_mileage;
        }
      });
      loading =
        loading /
        (this.analitics_data[0].cars_numbers > 0
          ? this.analitics_data[0].cars_numbers
          : 1.0);
      return parseFloat(loading).toFixed(2);
    },
    averageCost() {
      let loading = 0.0;
      this.analitics_data.forEach(
        (item) => (loading += Math.round(item.bookings_average_cost))
      );
      loading =
        loading /
        (this.analitics_data[0].cars_numbers > 0
          ? this.analitics_data[0].cars_numbers
          : 1.0);
      return parseFloat(loading).toFixed(2);
    },
    with_property_warn() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "warning",
        title: this.$t("errors.with_property_warn"),
      });
    },
    findCurrencySymbol(id) {
      if (id != null) {
        if (this.currencies.find((currency) => currency.id == id)) {
          return this.currencies.find((currency) => currency.id == id).symbol;
        }
      }
    },
    replaceToCar(car) {
      this.$router.push({ path: `/cars/${car.id}` });
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    GChart,
  },
};
</script>
<style>
.is-mobile .v-data-footer__select {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.is-mobile .v-data-footer__icons-before {
  margin: 0 auto !important;
}

.is-mobile .v-data-footer__icons-after {
  margin: 0 auto !important;
}
</style>
