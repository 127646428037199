<template>
  <v-container grid-list-md text-xs-center>
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card>
      <v-card-title>
        <h1>{{ $t('fines.new') }}</h1>
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm1 md2>
                <v-text-field v-model.number="fine.sum" :label="$t('custom.amount')"></v-text-field>
              </v-flex>
              <v-flex xs12 sm3 md5>
                <v-text-field v-model="fine.number" :label="$t('fines.number')"></v-text-field>
              </v-flex>
              <v-flex xs12 sm2 md5>
                <vc-date-picker v-model="start_date" mode="dateTime" :attributes="attrs_calendar" is24hr
                  :locale="$i18n.locale" :is-dark="$vuetify.theme.dark">
                  <template v-slot="{ inputEvents }">
                    <v-text-field :value="start_date_formatted" v-on="inputEvents" :label="$t('custom.date')" readonly
                      hide-details />
                  </template>
                </vc-date-picker>
              </v-flex>
              <v-flex xs12 sm2 md2>
                <v-text-field v-model="fine.booking_id" :label="$t('bookings.number_booking')"></v-text-field>
              </v-flex>
              <v-flex xs12 sm3 md5>
                <v-autocomplete v-model="client" item-text="fullname" item-value="id" :loading="isLoadingClients"
                  :search-input.sync="search_client" flat clearable hide-no-data hide-details
                  :label="$t('bookings.select_client')" :items="clients" return-object>
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 sm2 md5>
                <v-select v-model="fine.state" clearable :items="fines_state" item-text="text" item-value="value"
                  :label="$t('bookings.select_state')"></v-select>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-textarea v-model="fine.description" auto-grow rows="2" :label="$t('fines.description')"
                  :placeholder="$t('fines.description_2')"></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
          <v-flex md12 class="text-center">
            <v-btn large color="success" @click="submit()">{{ $t('custom.save') }}</v-btn>
          </v-flex>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
// import flatPickr from "vue-flatpickr-component";
// import { Russian } from "flatpickr/dist/l10n/ru";
// import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";
// import "flatpickr/dist/flatpickr.css";
// import "flatpickr/dist/themes/airbnb.css";
// import "flatpickr/dist/plugins/confirmDate/confirmDate.css";
// flatpickr.localize(Russian);
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      fine: {
        sum: null,
        date: null,
        state: this.$t('fines_state.created'),
        description: null,
        number: null,
        client_id: null,
        booking_id: null
      },
      start_date: null,
      start_date_formatted: null,
      date_format: null,
      start_date_menu: false,
      fines_state: [
        { text: this.$t('fines_state.created'), value: "Создан" },
        { text: this.$t('fines_state.notify'), value: "Оповещён" },
        { text: this.$t('fines_state.paid'), value: "Оплачен" },
        { text: this.$t('fines_state.refund'), value: "Возмещён" }
      ],
      attrs_calendar: [
        {
          key: "today",
          highlight: {
            color: "blue",
            fillMode: "outline",
            contentClass: "italic",
          },
          dates: new Date(),
        },
      ],
      search_client: null,
      clients: [],
      client: null,
      isLoadingClients: false,
      workers: [],
      selected_worker: null,
      timeout: null,
      isMobile: false,
      isLoading: false,
      error: ""
    }
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    search_client(val) {
      if (this.client) {
        return;
      }
      if (this.search_client == "" || this.search_client == " ") {
        this.search_client = null;
      } else {
        let self = this;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.loadClients();
          }, 200); // delay
        }
        debounce();
      }
    },
    start_date(val) {
      if (val) {
        this.start_date_formatted = moment(val).format("DD-MM-YYYY HH:mm");
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role
      } else {
        return ""
      }
    },
  },
  methods: {
    submit() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let fine = this.fine;
        let self = this;
        if ((fine.sum, fine.number)) {
          this.isLoading = true;
          this.axios
            .post(
              "/api/v1/fines",
              {
                fines: {
                  sum: fine.sum,
                  date: this.start_date,
                  state: fine.state,
                  description: fine.description,
                  number: fine.number,
                  client_id: this.client ? this.client.id : null,
                  booking_id: fine.booking_id
                }
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken
                }
              }
            )
            .then(response => {
              this.$router.replace("/fines");

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t('fines.added_success'),
                text: this.$t('fines.added_success_text')
              });
            })
            .catch(error => {
              this.setError(error, this.$t('errors.fine_add'));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t('errors.fine_add'),
                text: this.error
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t('errors.all_fields_title'),
            text: this.$t('errors.fine_add_text')
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t('errors.no_access')
        });
      }
    },
    loadClients() {
      this.isLoadingClients = true;
      this.axios
        .post(
          `/api/v1/index_new_booking_page`,
          {
            search: this.search_client,
            meilisearch: true,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          console.log(response.data)
          this.clients = response.data.query ? response.data.query : (response.data ? response.data : []);
          this.clients.forEach((client) => {
            if (!client.entity) {
              client["fullname"] =
                client.lastname +
                " " +
                client.name +
                " " +
                client.middlename +
                " " +
                (client.phone
                  ? client.phone.slice(client.phone.length - 4)
                  : "");
            } else {
              client["fullname"] =
                client.entity_name +
                ", " +
                client.reg_form +
                " " +
                " - " +
                (client.entity_phone
                  ? client.entity_phone.slice(client.entity_phone.length - 4)
                  : "");
            }
          });
        })
        .catch((error) => {
          this.isLoadingClients = false;
          this.setError(error, this.$t("errors.clients_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingClients = false));
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    }
  },
  components: {
    // flatPickr
  }
};
</script>
