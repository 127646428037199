<template>
  <span v-resize="checkMobile" :style="isMobile ? 'padding: 5px !important;' : ''">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card>
      <v-card-text ref="chatContainer" style="scroll-margin-bottom: 150px;">
        <v-row class="no-gutters elevation-4">
          <v-col cols="12" sm="3" class="flex-grow-1 flex-shrink-0" style="border-right: 1px solid #0000001f;">
            <v-responsive class="overflow-y-auto fill-height" height="800">
              <v-list subheader>
                <v-list-item-group>
                  <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('custom.search')"
                    class="mx-3 my-3" single-line clearable hide-details></v-text-field>
                </v-list-item-group>
                <v-list-item-group v-model="activeChat">
                  <template v-for="(item, index) in parents">
                    <v-list-item :key="`parent${index}`" :value="item.id" @click="loadMessages(item)">
                      <v-list-item-avatar color="grey lighten-1 white--text">
                        <v-avatar :color="item.bookings ? 'success' : 'grey lighten-1'" size="40" class="m-2">
                          <v-flex class="text-uppercase">
                            {{ item.name ? item.name[0] : '' }} {{ item.lastname ? item.lastname[0] : '' }}
                          </v-flex>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-badge v-if="item.have_unreaded_message" color="deep-purple accent-4" dot>
                            <span v-text="item.name + ' ' + item.lastname"></span>
                          </v-badge>
                          <span v-else v-text="item.name + ' ' + item.lastname"></span>
                        </v-list-item-title>
                        <v-list-item-subtitle v-text="item.phone" />
                        <v-list-item-subtitle>
                          {{ returnBookingsInfo(item) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="`chatDivider${index}`" class="my-0" />
                  </template>
                </v-list-item-group>
              </v-list>
            </v-responsive>
          </v-col>

          <v-col v-if="messages && messages.length > 0">
            <v-card v-for="(message, index) in messages" :key="index" flat>
              <v-list-item :key="message.timestamp" v-if="message.type != 'outgoing'" class="">
                <v-list-item-avatar class="align-self-start mr-2">
                  <v-avatar size="40" color="success" @click="replaceToClientCard()" v-tooltip="{
                    content: `${client.lastname} ${client.name} ${client.middlename} ${client.phone}`,
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }">
                    {{ client.name ? client.name[0] : '' }} {{ client.lastname ? client.lastname[0] : '' }}
                    <!-- <v-img :src="getAvatar()"></v-img> -->
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content class="received-message">
                  <v-card v-if="message.typeMessage == 'textMessage'" color="success" class="flex-none">
                    <v-card-text class="white--text pa-2 d-flex flex-column">
                      <span class="text-caption"></span>
                      <span class="align-self-start text-subtitle-1">{{ message.textMessage }}</span>
                      <span class="text-caption font-italic align-self-end">
                        <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale">
                        </timeago>
                      </span>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="message.typeMessage == 'imageMessage'" color="success" class="flex-none">
                    <v-card-text class="white--text pa-2 d-flex flex-column">
                      <span class="text-caption">{{ message.textMessage }}</span>
                      <v-img :lazy-src="'data:image/png;base64,' + message.jpegThumbnail" max-height="150"
                        max-width="250" :src="message.downloadUrl"
                        @click="saveFile(message.downloadUrl, message.fileName)"></v-img>
                      <span class="text-caption font-italic align-self-end">
                        <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale">
                        </timeago>
                      </span>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="message.typeMessage == 'audioMessage'" color="success" class="flex-none">
                    <v-card-text class="white--text pa-2 d-flex flex-column">
                      <span class="text-caption">{{ message.textMessage }}</span>
                      <span class="align-self-start text-subtitle-1">
                        <audio controls>
                          <source :src="message.downloadUrl" type="audio/ogg">
                          <source :src="message.downloadUrl" type="audio/mpeg">
                          Your browser does not support the audio element.
                        </audio>
                      </span>
                      <span class="text-caption font-italic align-self-end">
                        <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale">
                        </timeago>
                      </span>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="message.typeMessage == 'documentMessage'" color="success" class="flex-none">
                    <v-card-text class="white--text pa-2 d-flex flex-column">
                      <span class="text-caption">{{ message.textMessage }}</span>
                      <v-btn color="primary" text @click="saveFile(message.downloadUrl, message.fileName)">
                        {{ message.fileName }}
                      </v-btn>
                      <span class="text-caption font-italic align-self-end">
                        <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale">
                        </timeago>
                      </span>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="message.typeMessage == 'videoMessage'" color="success" class="flex-none">
                    <v-card-text class="white--text pa-2 d-flex flex-column" style="cursor: pointer;">
                      <span class="text-caption">{{ message.textMessage }}</span>
                      <v-img :lazy-src="'data:image/png;base64,' + message.jpegThumbnail" max-height="150"
                        max-width="250" :src="message.downloadUrl"
                        @click="saveFile(message.downloadUrl, message.fileName)">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-icon color="grey lighten-1" dark size="64"
                              @click="saveFile(message.downloadUrl, message.fileName)">
                              mdi-youtube
                            </v-icon>
                          </v-row>
                        </template>
                      </v-img>
                      <span class="text-caption font-italic align-self-end">
                        <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale">
                        </timeago>
                      </span>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="message.typeMessage == 'extendedTextMessage'" color="success" class="flex-none">
                    <v-card-text class="white--text pa-2 d-flex flex-column">
                      <span class="text-caption"></span>
                      <span class="align-self-start text-subtitle-1">{{ message.textMessage }}</span>
                      <!-- <span class="align-self-start text-subtitle-1">{{ message.extendedTextMessage }}</span> -->
                      <span class="text-caption font-italic align-self-end">
                        <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale">
                        </timeago>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else :key="message.timestamp">
                <v-list-item-content class="sent-message justify-end">
                  <v-card v-if="message.typeMessage == 'textMessage'" color="primary" class="flex-none">
                    <v-card-text class="white--text pa-2 d-flex flex-column">
                      <span class="text-caption"></span>
                      <span class="align-self-start text-subtitle-1">{{ message.textMessage }}</span>
                      <span class="text-caption font-italic align-self-end">
                        <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale">
                        </timeago>
                      </span>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="message.typeMessage == 'extendedTextMessage'" color="primary" class="flex-none">
                    <v-card-text class="white--text pa-2 d-flex flex-column">
                      <span class="text-caption"></span>
                      <span class="align-self-start text-subtitle-1">{{ message.textMessage }}</span>
                      <!-- <span class="align-self-start text-subtitle-1">{{ message.extendedTextMessage }}</span> -->
                      <span class="text-caption font-italic align-self-end">
                        <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale">
                        </timeago>
                      </span>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="message.typeMessage == 'imageMessage'" color="primary" class="flex-none">
                    <v-card-text class="white--text pa-2 d-flex flex-column">
                      <span class="text-caption">{{ message.textMessage }}</span>
                      <v-img :lazy-src="'data:image/png;base64,' + message.jpegThumbnail" max-height="150"
                        max-width="250" :src="message.downloadUrl"
                        @click="saveFile(message.downloadUrl, message.fileName)"></v-img>
                      <span class="text-caption font-italic align-self-end">
                        <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale">
                        </timeago>
                      </span>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="message.typeMessage == 'audioMessage'" color="primary" class="flex-none">
                    <v-card-text class="white--text pa-2 d-flex flex-column">
                      <span class="text-caption">{{ message.textMessage }}</span>
                      <span class="align-self-start text-subtitle-1">
                        <audio controls>
                          <source :src="message.downloadUrl" type="audio/ogg">
                          <source :src="message.downloadUrl" type="audio/mpeg">
                          Your browser does not support the audio element.
                        </audio>
                      </span>
                      <span class="text-caption font-italic align-self-end">
                        <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale">
                        </timeago>
                      </span>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="message.typeMessage == 'documentMessage'" color="primary" class="flex-none">
                    <v-card-text class="white--text pa-2 d-flex flex-column">
                      <span class="text-caption">{{ message.textMessage }}</span>
                      <v-btn color="secondary" text @click="saveFile(message.downloadUrl, message.fileName)">
                        {{ message.fileName ? message.fileName : 'Download' }}
                      </v-btn>
                      <span class="text-caption font-italic align-self-end">
                        <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale">
                        </timeago>
                      </span>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="message.typeMessage == 'videoMessage'" color="primary" class="flex-none">
                    <v-card-text class="white--text pa-2 d-flex flex-column" style="cursor: pointer;">
                      <span class="text-caption">{{ message.textMessage }}</span>
                      <v-img :lazy-src="'data:image/png;base64,' + message.jpegThumbnail" max-height="150"
                        max-width="250" :src="message.downloadUrl"
                        @click="saveFile(message.downloadUrl, message.fileName)">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-icon color="grey lighten-1" dark size="64"
                              @click="saveFile(message.downloadUrl, message.fileName)">
                              mdi-youtube
                            </v-icon>
                          </v-row>
                        </template>
                      </v-img>
                      <span class="text-caption font-italic align-self-end">
                        <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale">
                        </timeago>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-list-item-content>
                <v-list-item-avatar class="align-self-start ml-2" @click="replaceToClientCard()">
                  <v-avatar size="40" color="primary">
                    {{ $t('custom.you') }}
                  </v-avatar>
                  <!-- <v-img :src="getAvatar()"></v-img> -->
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
            <v-container class="ma-0 px-3" v-if="!isLoading && messages && messages.length > 0">
              <v-row no-gutters>
                <v-col>
                  <v-flex class="d-flex align-center">
                    <v-file-input class="flex-none" hide-input hide-details flat truncate-length="15"
                      v-model="sended_file" dense inline solo></v-file-input>
                    <v-textarea rows="1" autofocus auto-grow v-model="sended_message" class="w-full"
                      :placeholder="$t('companies.type_something')" @keypress.enter="sendMesssage()"></v-textarea>
                    <v-btn icon class="ml-4" @click="sendMesssage()"><v-icon>mdi-send</v-icon></v-btn>
                  </v-flex>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col v-else>
            <v-card flat class="w-full">
              <v-list-item class="w-full">
                <v-list-item-content class="w-full">
                  <v-card class="w-full p-5">
                    <v-card-text class="white--text pa-2 w-full text-center">
                      <h3>{{ $t('whatsapp.select_client') }}</h3>
                    </v-card-text>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import { saveAs } from "file-saver";
import PullToRefresh from "pulltorefreshjs";

export default {
  data() {
    return {
      client: null,
      client_profile: null,
      activeChat: null,
      parents: [],
      bookings: [],
      messages: [],
      sended_message: null,
      sended_file: null,
      search: null,
      isMobile: false,
      isLoading: false,
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
    if (this.$store.getters.isLoggedIn) {
      this.$cable.subscribe({
        channel: "WhatsappChannel",
        room: this.$store.getters.getCompany.company.id,
      });
    }
  },
  updated() {
    // Только тут работает
    // this.scrollToBottom();
    // выключаем crisp
    if (typeof $crisp !== "undefined" && $crisp) {
      $crisp.push(["do", "chat:hide"]);
    }
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      // выключаем crisp
      if (typeof $crisp !== "undefined" && $crisp) {
        $crisp.push(["do", "chat:hide"]);
      }
      // get search from state
      this.search = this.$store.getters.getWhatsappSearchClient;
      this.getClients();
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "WhatsappChannel",
      room: this.$store.getters.getCompany.company.id,
    });
  },
  channels: {
    WhatsappChannel: {
      connected() {
        console.log("Connected to WhatsappChannel");
      },
      // rejected() {
      //   console.log("rejected");
      // },
      received(data) {
        if (data) {
          if (data.client && data.client.id) {
            if (this.parents.find((item) => item.id == data.client.id)) {
              let client = this.parents.find((item) => item.id == data.client.id);
              // заменяем данные клиента в родителях
              client.have_unreaded_message = true;
              this.parents.splice(this.parents.findIndex((item) => item.id == data.client.id), 1, client);

              if (this.activeChat && this.activeChat == data.client.id) {
                this.messages.push(data);
                console.log(data)
              }
            } else {
              this.parents.unshift(data.client);
            }
          }
        }
      },
      // disconnected() {
      //   console.log("disconnected");
      // }
    },
  },
  watch: {
    search() {
      this.$store.commit("setWhatsappSearchClient", this.search);
      if (this.search == "") {
        this.search = null;
      } else {
        // set search to state
        let self = this;
        this.page = 1;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.getClients();
          }, 300); // delay
        }
        debounce();
      }
    },
  },
  computed: {
    clients_sources() {
      if (this.$store.getters.getCompany.company.clients_sources) {
        return this.$store.getters.getCompany.company.clients_sources;
      } else {
        return [];
      }
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    company() {
      if (this.$store.getters.getCompany) {
        return this.$store.getters.getCompany.company;
      } else {
        return null;
      }
    },
  },
  methods: {
    loadAllMessages() {
      if (this.company && (this.company.chat_api_instance && this.company.chat_api_instance.length > 0 && this.company.chat_api_token && this.company.chat_api_token.length > 0)) {
        // приводим номер к виду 79999999999
        let phone = this.client.phone.replace(/[^\d]/g, "").replace(/^8/, "7")
        let self = this;
        this.isLoading = true;
        this.axios({
          url: `https://api.green-api.com/waInstance${this.company.chat_api_instance}/lastIncomingMessages/${this.company.chat_api_token}`,
          method: "get",
        })
          .then((response) => {
            this.all_messages = response.data.reverse();
            this.activeChat = this.all_messages[0];
            this.axios({
              url: `https://api.green-api.com/waInstance${this.company.chat_api_instance}/lastOutgoingMessages/${this.company.chat_api_token}`,
              method: "get",
            })
              .then((response) => {
                this.all_messages + response.data.reverse();
                // sort by date
                this.all_messages.sort(function (a, b) {
                  return a - b;
                });

                // группируем все сообщения по чатам
                let grouped = this.all_messages.reduce((r, a) => {
                  r[a.chatId] = [...(r[a.chatId] || []), a];
                  return r;
                }, {});

                // Получаем массив всех чатов
                this.parents = Object.keys(grouped).map((key) => {
                  return grouped[key];
                });


              })
              .catch((error) => {
                this.isLoading = false;
                this.setError(error, this.$t("errors.error"));
                console.log(error);
              })
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          title: this.$t("errors.error"),
          text: this.$t("errors.error"),
          icon: "error",
          showCancelButton: false,
        });
      }
    },
    loadMessages(client) {
      if (this.company && (this.company.chat_api_instance && this.company.chat_api_instance.length > 0 && this.company.chat_api_token && this.company.chat_api_token.length > 0)) {
        let phone = client.phone.replace(/[^\d]/g, "");
        this.client = client;
        this.activeChat = client.id;
        let finded_client = this.parents.find((item) => item.id == client.id);
        // заменяем данные клиента в родителях
        finded_client.have_unreaded_message = false;
        this.parents.splice(this.parents.findIndex((item) => item.id == client.id), 1, finded_client);
        let self = this;
        this.isLoading = true;
        this.axios({
          url: `https://api.green-api.com/waInstance${this.company.chat_api_instance}/getChatHistory/${this.company.chat_api_token}`,
          method: "post",
          data: {
            "chatId": `${phone}@c.us`,
            "count": 50
          },
        })
          .then((response) => {
            this.messages = response.data.reverse();
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          title: this.$t("errors.error"),
          text: this.$t("errors.error"),
          icon: "error",
          showCancelButton: false,
        });
      }
    },
    getClients() {
      this.isLoading = true;
      let self = this;
      this.axios
        .post(
          `/api/v1/index_clients_whatsapp`,
          {
            search: this.search,
            page: 1,
            per_page: 20,
            sort_by: "id",
            direction: "desc",
            debtor: false,
            entity: false,
            meilisearch: true,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.parents = response.data.clients;
          this.bookings = response.data.bookings;

          // ищем брони клиента и добавляем в него
          this.parents.forEach((parent) => {
            self.bookings.forEach((booking) => {
              if (booking.client_id == parent.id) {
                if (parent.bookings) {
                  parent.bookings.push(booking);
                } else {
                  parent.bookings = [booking];
                }
              }
            });
          });
        })
        .catch((error) => {
          console.log(error);
          this.setError(error, this.$t("errors.clients_load"));
        })
        .finally(() => (this.isLoading = false));
    },
    lastMessage(messages) {
      if (messages && messages.length > 0) {
        return messages[messages.length - 1].textMessage;
      } else {
        return null;
      }
    },
    returnBookingsInfo(client) {
      let bookings = client.bookings;
      if (bookings && bookings.length > 0) {
        let result = "";
        bookings.forEach((booking) => {
          result += `${booking.car_code} ${booking.start_date} ${booking.end_date} \n`;
        });
        return result;
      } else {
        return null;
      }
    },
    getAvatar() {
      if (this.client_profile == null) {
        let self = this;
        // this.isLoading = true;
        let phone = this.client.phone.replace(/[^\d]/g, "");
        let avatar_url = "https://via.placeholder.com/50";
        this.axios({
          url: `https://api.green-api.com/waInstance${this.company.chat_api_instance}/getAvatar/${this.company.chat_api_token}`,
          method: "post",
          data: {
            "chatId": `${phone}@c.us`
          },
        })
          .then((response) => {
            this.client_profile = {
              avatar: response.data.avatar,
              name: response.data.name
            }
            console.log(response.data);
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));

        if (this.client_profile && this.client_profile.avatar) {
          return this.client_profile.avatar;
        } else {
          return avatar_url;
        }
      } else if (this.client_profile && this.client_profile.avatar) {
        return this.client_profile.avatar;
      }
    },
    sendMesssage() {
      if ((this.sended_message && this.sended_message != "" && this.sended_message != " " && this.sended_message.length > 0) || this.sended_file) {
        let phone = this.client.phone.replace(/[^\d]/g, "");
        let self = this;
        if (this.sended_file) {
          this.sendFile(phone);
        } else {
          this.isLoading = true;
          this.axios({
            url: `https://api.green-api.com/waInstance${this.company.chat_api_instance}/sendMessage/${this.company.chat_api_token}`,
            method: "post",
            data: {
              "chatId": `${phone}@c.us`,
              "message": this.sended_message
            },
          })
            .then((response) => {
              this.scrollToBottom();
              this.messages.push({
                "type": 'outgoing',
                "typeMessage": "textMessage",
                "textMessage": this.sended_message,
                "timestamp": Math.floor(Date.now() / 1000)
              });
              this.sended_message = "";
              console.log(response.data);
            })
            .catch((error) => {
              this.isLoading = false;
              this.setError(error, this.$t("errors.error"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          title: this.$t("errors.error"),
          text: this.$t("errors.error"),
          icon: "error",
          showCancelButton: false,
        });
      }
    },
    sendFile(phone) {
      this.isLoading = true;
      let file = this.sended_file
      // make file form-data
      let formData = new FormData();
      formData.append("file", file);
      formData.append("chatId", `${phone
        }@c.us`);
      if (this.sended_message && this.sended_message != "" && this.sended_message != " " && this.sended_message.length > 0) {
        formData.append("caption", this.sended_message);
      }

      this.axios({
        url: `https://api.green-api.com/waInstance${this.company.chat_api_instance}/sendFileByUpload/${this.company.chat_api_token}`,
        method: "post",
        data: formData
      })
        .then((response) => {
          this.scrollToBottom();
          this.messages.push({
            "type": 'outgoing',
            "typeMessage": "documentMessage",
            "downloadUrl": response.data.urlFile,
            "textMessage": this.sended_message,
            "fileName": file.name,
            "timestamp": Math.floor(Date.now() / 1000)
          });
          this.sended_message = "";
          this.sended_file = null;

          console.log(response.data);
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    saveFile(downloadUrl, fileName) {
      saveAs(downloadUrl, fileName);
    },
    scrollToBottom() {
      // <v-card-text ref="chatContainer" style="scroll-margin-bottom: 150px;">
      var container = this.$refs.chatContainer;
      this.$nextTick(function () {
        if (container) {
          container.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
      });
    },
    formatTime(time) {
      return moment.unix(time).toDate();
    },
    replaceToClientCard() {
      this.$router.push({
        path: `/clients/${this.client.id}`,
      });
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
        this.paymentsPanel = [];
      } else {
        this.paymentsPanel = 0;
        this.isMobile = false;
      }
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
<style scoped>
.chat-message {
  display: unset !important;
  white-space: break-spaces;
}

.chat-screen {
  max-height: 320px;
  overflow-y: auto;
}

.flex-none {
  flex: unset;
}

.received-message::after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 54px;
  right: auto;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #4caf50 transparent transparent transparent;
}

.sent-message::after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 54px;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #2196f3 transparent transparent transparent;
}
</style>
